$(document).ready(function ($) {
  //alert( window.innerWidth );

  if (screen.width > 991) {
    new WOW().init();
  }

  if (screen.width < 1200) {
    ////certificates-items slider
    $('.certificates-items').slick({
      dots: true,
      autoplay: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesPerRow: 1,
      rows: 1,
      nextArrow: '<span class="nextArrow"></span>',
      prevArrow: '<span class="prevArrow"></span>',
      responsive: [
        {
          breakpoint: 499,
          settings: {
            slidesPerRow: 1,
            adaptiveHeight: true
            // lazyLoad:'progressive'
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesPerRow: 2,
            adaptiveHeight: true
            // lazyLoad:'progressive'
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesPerRow: 3,
            adaptiveHeight: true
            // lazyLoad:'progressive'
          }
        }

      ]
    });
    //competencies
    $(".competencies-item .list").hide();
    $(".competencies-item .title").each(function(e){
      $(this).click(function(e){

        if($(this).parent().hasClass("active")){
          $(this).parent().removeClass("active").find(".list").slideUp();
        } else{
          $(this).parent().parent().parent().parent().parent().find(".active").removeClass("active").find(".list").slideUp();
          $(this).parent().addClass("active").find(".list").slideDown();
        }
      })
    });
  }

  //if (screen.width < 992) {
  if (screen.width < 1200) {
    $('.header-advantages').slick({
      dots: true,
      autoplay: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesPerRow: 1,
      rows: 1,
      responsive: [
        {
          breakpoint: 599,
          settings: {
            slidesPerRow: 1,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesPerRow: 2,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesPerRow: 4,
            dots: false,
            infinite: true,
            adaptiveHeight: true
          }
        }
      ]
    });
  }

  //portfolio

  $(function () {
    if ($(".portfolio  .row .col-12:hidden").length <= 9) {
      $(".portfolio-more").fadeOut('slow');
    }
    var portfolioItem =  $(".portfolio .row");
    portfolioItem.find(".col-12:nth-child(1n+10)").attr('data-wow-delay', '0.4s').attr('data-wow-duration','1s');
    portfolioItem.find(".col-12:nth-child(2n+3)").attr('data-wow-delay', '0.8s').attr('data-wow-duration','1s');
    portfolioItem.find(".col-12:nth-child(4n+1)").attr('data-wow-delay', '1s').attr('data-wow-duration','1s');
    portfolioItem.find(".col-12:nth-child(7n+1)").attr('data-wow-delay', '1.2s').attr('data-wow-duration','1s');
    $(".portfolio .row .col-12").slice(0, 9).show().addClass("active");
    $(".portfolio-more").on('click', function () {
      $(".portfolio .row .col-12:hidden").slice(0, 9).slideDown().addClass("active");
      if ($(".portfolio .row .col-12:hidden").length == 0) {
        $(".portfolio-more").fadeOut('slow');
      }
      $('html,body').animate({
        scrollTop: $(this).offset().top
      }, 1500);
    });
  });
  //certificates
  if (screen.width >1199) {
    $(function () {
      var certificatesItem =  $(".certificates .row .col-12");

      if ($(".certificates .row .col-12:hidden").length <= 8) {
        $(".certificates-more").fadeOut('slow');
      }


      $(".certificates .row .col-12").slice(0, 8).show().addClass("active");
      $(".certificates-more").on('click', function () {
        $(".certificates .row .col-12:hidden").slice(0, 8).slideDown().addClass("active");

        if ($(".certificates .row .col-12:hidden").length == 0) {
          $(".certificates-more").fadeOut('slow');
        }
        $('html,body').animate({
          scrollTop: $(this).offset().top - 650
        }, 1500);
      });
    });
  }

  if (window.innerWidth < 1023){
    $(".kd-item").click(function () {
      return true;
    });
  }
  if ((window.innerWidth > 1023) && (window.innerWidth < 1199)) {
    $(".kd-item").click(function () {
      //alert();
      //alert(window.innerWidth);
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        return true;
      } else {
        $(".kd-item").removeClass("active");
        $(this).addClass("active");
        //alert("add class active");
       return false;
      }
    });
  }

  $(".contact-dp__down").click(function(){
    //window.location.href = "resume.doc";
    window.open("http://molevanny.ru/portfolio/resume.pdf");
  });
  $(".contact-dp__print").click(function(){
    window.open("http://molevanny.ru/portfolio/resume.pdf");
  });
  //печать резюме pdf
/*  function printTrigger(elementId) {
    var getMyFrame = document.getElementById(elementId);
    getMyFrame.focus();
    getMyFrame.contentWindow.print();
  }
  $(".contact-dp__print").click(function(){

  printTrigger('iFramePdf');
  });*/

  //maska tel
  $(function () {
    $("input[type='tel']").mask("+7 (999) 999-99-99");
  });

  //form ajax
  $('form').submit(function(e) {
    var $form = $(this);
    $.ajax({
      type: $form.attr('method'),
      url: $form.attr('action'),
      data: $form.serialize()
    }).done(function() {
      console.log('Сообщение с сайта отправлено!');
      $.fancybox.close();
      $.fancybox.open({
        src: '#modal-thank',
        type: 'inline',
        opts: {}
      });
    }).fail(function() {
      console.log('Упс ошибка =(');
    });
    e.preventDefault();
  });

});

